import superagent from 'superagent';
import Throttle from 'superagent-throttle';
import config from '../config';

let throttle = new Throttle({
  active: true,     // set false to pause queue
  rate: 20,          // how many requests can be sent every `ratePer`
  ratePer: 1000,   // number of ms in which `rate` requests may be sent
  concurrent: 10     // how many requests can be sent concurrently
});
import store from 'store';
const methods = ['get', 'post', 'put', 'patch', 'del'];

export function formatUrl(path) {
  let adjustedPath = path[0] !== '/' ? (path[0] === ':' ? '' + path : '/' + path ): path;
  // Prepend host and port of the API server to the path.
  let apiHost = config.apiHost;
  let appendProtocol = true;
  let protocol = "";
  if(path[0] === ':') {
    if (process.env.REACT_APP_GOLANG_APIHOST && adjustedPath.includes(":9000")) {
      adjustedPath = adjustedPath.replace(':9000', '');
      apiHost = process.env.REACT_APP_GOLANG_APIHOST;
    } else if (process.env.REACT_APP_APIHOST_LARAVEL && adjustedPath.includes(":peerapi")) {
      adjustedPath = adjustedPath.replace(':peerapi', '');
      apiHost = process.env.REACT_APP_APIHOST_LARAVEL;
    } else if (process.env.REACT_APP_PYX_API_HOST && adjustedPath.includes(":pyxapi")) {
      adjustedPath = adjustedPath.replace(':pyxapi', '');
      apiHost = process.env.REACT_APP_PYX_API_HOST;
      appendProtocol = false;
    } else {
      apiHost = apiHost.replace('/platformapi', '');
    }
  }

  if(appendProtocol) {
    if(process.env.REACT_APP_NODE_ENV === 'sandbox' || process.env.REACT_APP_NODE_ENV === 'staging') {
      protocol = 'https://';
    } else {
      protocol = '//';
    }
  }

  return protocol + apiHost + adjustedPath;
}

export async function appendAuth(url) {
  if(!!url && (url.includes('X-Amz-Signature') || url.includes('amazonaws'))) return url;
  const reduxState = store.getState();
  const user = reduxState.auth.user;
  const oidcUser = await reduxState.userManager.userManager.getUser();
  const hasQueryParams = url.includes('?');
  const queryParams = [];

  if (user?.id && user?.group) {
    queryParams.push(`user_type_id=${user.id}`, `user_type_group=${user.group}`);
  }
  if (oidcUser?.access_token) {
    queryParams.push(`access_token=${oidcUser.access_token}`);
  }

  if (queryParams.length > 0) {
    url += (hasQueryParams ? '&' : '?') + queryParams.join('&');
  }

  return url;
}

class _ApiClient {
  constructor() {
    methods.forEach((method) =>
      this[method] = (path, options) => new Promise(async (resolve, reject) => {
        if(path.includes(":pyxapi")) {
          this.handlePyxRequest(method, path, options, resolve, reject);
        } else {
          this.handlePeerRequest(method, path, options, resolve, reject);
        }
      }));
  }

  async handlePyxRequest(method, path, { params, data, port } = {}, resolve, reject) {
    const reduxState = store.getState();
    const currentVersion = global.appVersion;
    const request = superagent[method](formatUrl(path))
    const route = window.location.hash.substring(1);

    if (params) {
      request.query(params);
    }
    if (port) {
      request.set('port', port)
    }
    const oidcUser = await reduxState.userManager.userManager.getUser();
    if (oidcUser?.access_token) {
      request.set('Authorization', 'Bearer ' + oidcUser.access_token);
    }

    request.withCredentials = true;
    if (data) {
      request.send(data);
    }
    request.use(throttle.plugin());
    request.end((err, res) => {
      if (!err) {
        let parseResponse = false;
        try {
          parseResponse = JSON.parse(res.text);
        } catch(e) {
        }
        resolve(parseResponse);
      } else {
        if (res && res.text) {
          try {
            reject(JSON.parse(res.text));
          } catch (e) {
            reject('error');
          }
        } else {
          reject(err);
        }
      }
    });
  }

  async handlePeerRequest(method, path, { params, data, port } = {}, resolve, reject) {
    const reduxState = store.getState();
    const currentVersion = global.appVersion;
    const request = superagent[method](formatUrl(path));
    const route = window.location.hash.substring(1);
    const route_match = route.match(/^\/(?<org_id>\d+)\/(?:admin|es|navigator|supporter)(?:\/|$)/);
    let addlData = false;
    if (route.indexOf('/es/mentee/') > -1 || route.indexOf('/navigator/mentee/') > -1 || route.indexOf('/supporter/mentee/') > -1 || route.indexOf('/admin/mentees/') > -1) {
      let { menteeProfile } = reduxState.engagedSupport;
      if (menteeProfile?.id) {
        addlData = {};
        addlData['mentee_id'] = menteeProfile.id;
        addlData['mentee_master_id'] = menteeProfile.mentee_master_id;
        addlData['es_program_base_id'] = menteeProfile.epb_id;
        addlData['es_program_node_id'] = menteeProfile.es_program_node_id;
        addlData['es_program_pn_node_id'] = menteeProfile.es_program_pn_node_id;
        addlData['es_program_supporter_node_id'] = menteeProfile.es_program_supporter_node_id;
        addlData['es_program_supporter_base_id'] = menteeProfile.es_program_supporter_base_id;
        addlData['org'] = route_match?.groups?.org_id || menteeProfile.associated_org_id;
        addlData['user_relation'] = menteeProfile.user_relation;
      } else {
        menteeProfile = reduxState.mentee.menteeProfile;
        if (menteeProfile?.id) {
          addlData = {};
          addlData['mentee_id'] = menteeProfile.id;
          addlData['mentee_master_id'] = menteeProfile.mentee_master_id;
          addlData['es_program_base_id'] = menteeProfile.epb_id;
          addlData['es_program_node_id'] = menteeProfile.es_program_node_id;
          addlData['es_program_pn_node_id'] = menteeProfile.es_program_pn_node_id;
          addlData['es_program_supporter_node_id'] = menteeProfile.es_program_supporter_node_id;
          addlData['es_program_supporter_base_id'] = menteeProfile.es_program_supporter_base_id;
          addlData['org'] = route_match?.groups?.org_id || menteeProfile.associated_org_id;
          addlData['user_relation'] = menteeProfile.user_relation;
        }
      }
    }

    const { user, selectedOrg } = reduxState.auth;

    if (user?.id && user?.group) {
      request.set('x-user-type-id', user.id);
      request.set('x-user-type-group', user.group);
    }

    if (user?.id && !(addlData?.org)) {
      if (['get', 'del'].includes(method)) {
        if(!params) params = {};
        if(!params.org) params['org'] = params.org_ids || params.org_id || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
      } else {
        if(!data) data = {};
        if(!data.org) data['org'] = data.org_ids || data.org_id || (params && (params.org || params.org_ids || params.org_id)) || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
      }
    }
    if (params) {
      params['route'] = route;
      if (addlData) params['profile_data'] = addlData;
      if (currentVersion) params['current_app_version'] = currentVersion;
      request.query(params);
    }
    if (port) {
      request.set('port', port)
    }
    const oidcUser = await reduxState.userManager.userManager.getUser();
    if (oidcUser?.access_token) {
      request.set('Authorization', 'Bearer ' + oidcUser.access_token);
    }

    request.withCredentials = true;
    if (data) {
      data['route'] = route;
      if (user?.id && !(addlData?.org) && !data.org) data['org'] = data.org_ids || data.org_id || (params && (params.org || params.org_ids || params.org_id)) || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
      if (addlData) data['profile_data'] = addlData;
      if (currentVersion) data['current_app_version'] = currentVersion;
      request.send(data);
    }
    request.use(throttle.plugin());
    request.end((err, res) => {
      if (!err) {
        let parseResponse = false;
        try {
          parseResponse = JSON.parse(res.text);
        } catch(e) {
        }
        resolve(parseResponse);
      } else {
        if (res && res.text) {
          try {
            reject(JSON.parse(res.text));
          } catch (e) {
            reject('error');
          }
        } else {
          reject(err);
        }
      }
    });
  }

}

const ApiClient = _ApiClient;

export default ApiClient;
