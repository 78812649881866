import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import config from 'config';
import store from 'store';
import { fbRefreshToken } from 'redux/modules/auth';

let fbConfig = {
    apiKey: config.FIREBASE.KEY,
    authDomain: config.FIREBASE.DOMAIN,
    databaseURL: config.FIREBASE.URL,
    storageBucket: config.FIREBASE.BUCKET
};

const app = !firebase.apps.length ? firebase.initializeApp(fbConfig) : firebase.app();

let lastAuthCheck = 0;
const AUTH_CHECK_INTERVAL = 5 * 60 * 1000;

const ensureAuth = async () => {
    const now = Date.now();
    
    if (now - lastAuthCheck < AUTH_CHECK_INTERVAL && firebase.auth().currentUser) {
        return true;
    }

    const user = store.getState().auth?.user;
    if (!user?.fb_token) {
        throw new Error('No authentication token available');
    }

    try {
        if (!firebase.auth().currentUser) {
            await firebase.auth().signInWithCustomToken(user.fb_token);
        }
        lastAuthCheck = now;
        return true;
    } catch (error) {
        if (error.code === 'auth/invalid-custom-token') {
            const response = await store.dispatch(fbRefreshToken());
            if (response?.result) {
                await firebase.auth().signInWithCustomToken(response.result);
                lastAuthCheck = now;
                return true;
            }
        }
        throw error;
    }
};

const createAuthenticatedDatabase = () => {
    const originalDatabase = app.database();
    
    const authenticatedRef = (path) => {
        const originalRef = originalDatabase.ref(path);
        
        return new Proxy(originalRef, {
            get(target, property) {
                const original = target[property];
                
                if (['set', 'update', 'remove', 'push', 'once'].includes(property)) {
                    return async (...args) => {
                        await ensureAuth();
                        return original.apply(originalRef, args);
                    };
                }
                
                if (property === 'on') {
                    return async (...args) => {
                        await ensureAuth();
                        return original.apply(target, args);
                    };
                }
                
                return original;
            }
        });
    };

    return {
        ...originalDatabase,
        ref: authenticatedRef
    };
};

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        lastAuthCheck = Date.now();
    } else {
        lastAuthCheck = 0;
    }
});

// Export the wrapped app with all Firebase services
const wrappedApp = {
    auth: firebase.auth.bind(firebase),  // Keep original auth
    database: () => createAuthenticatedDatabase(),
    apps: firebase.apps
};

export default wrappedApp;