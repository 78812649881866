import { logout as revokeToken } from "redux/modules/auth";
import { FirebasePresenceHelper } from 'helpers/FirebasePresenceHelper';
import firebase from 'Firebase.js';
import 'firebase/compat/database';
import { PusherHelper } from "./PusherHelper";
import store from 'store';


export const logoutPreserveRoute = () => {
    const user = store.getState().auth.user
    sessionStorage.setItem('redirectToUrl', window.location.hash.replace('#',''));
    sessionStorage.setItem('redirectToQuery', window.location.search);
    if (user) {
      sessionStorage.setItem('sessionUser', JSON.stringify({group: user?.group, id: user?.id}));
      _logout();
    } else {
        window.location.href = "/";
    }
}

export const logout = () => {
    sessionStorage.removeItem('sessionUser');
    sessionStorage.removeItem('redirectToUrl');
    sessionStorage.removeItem('redirectToQuery');

    _logout();
}

export const _logout = () => {
    const user = store.getState().auth.user
    const userManager = store.getState().userManager;
    if(user && user.group) firebase.database().ref(`network_preferences/${user.group}/${user.role}${user.id}`).remove();
    FirebasePresenceHelper.remove(user);
    PusherHelper.disconnect();
    store.dispatch(revokeToken()).then(async () => {
        await userManager.userManager.removeUser();
        userManager.userManager.signoutSilent().catch(() => {});
    });
}